import { Action } from 'redux';
import { Task } from '../../common/types';

export enum types {
  CHANGE_TASK_LIST = '@@PAYMENT/CHANGE_TASK_LIST',
  FETCH_TASK_DEPENDENCE = '@@PAYMENT/FETCH_TASK_DEPENDENCE',
  CHANGE_VISUALISATION_LIST = '@@PAYMENT/CHANGE_VISUALISATION_LIST', //Доработать в сслучае добавление подгрузки при скроле
  CHANGE_STEP = '@@PAYMENT/CHANGE_STEP',
  PAID_SUCCESS = '@@PAYMENT/PAID_SUCCESS',
  CHANGE_PRICE_PARTS = '@@PAYMENT/CHANGE_PRICE_PARTS',
  PAYMENT_DETAILS = '@@PAYMENT/PAYMENT_DETAILS',
  SET_PAID = '@@PAYMENT/SET_PAID',
  SET_FREE = '@@PAYMENT/SET_FREE',
}

export type PayemntInfo = {
  amount: number;
  description?: string | null;
};

export interface changeStepAction extends Action<types.CHANGE_STEP> {
  stepNumber: number;
}

export interface changeTaskListAction extends Action<types.CHANGE_TASK_LIST> {
  tasks: Task[];
}

export interface fetchTaskDependenceAction
  extends Action<types.FETCH_TASK_DEPENDENCE> {
  id: number;
}

export interface paidSuccessAction extends Action<types.PAID_SUCCESS> {
  value: boolean;
}

export interface paymentDetailsAction extends Action<types.PAYMENT_DETAILS> {
  value: object;
}

export interface pricePartsAction extends Action<types.CHANGE_PRICE_PARTS> {
  members?: number;
  months?: number;
}
export interface setPaidAction extends Action<types.SET_PAID> {
  data: object;
  pay: PayemntInfo;
  companyId: number;
}
export interface setFreeAction extends Action<types.SET_FREE> {
  companyId: number;
}

export type PaymentsAction =
  | changeStepAction
  | pricePartsAction
  | fetchTaskDependenceAction
  | paidSuccessAction
  | paymentDetailsAction
  | changeTaskListAction
  | setPaidAction
  | setFreeAction;
