import {types, PaymentsAction} from './action_types';
import {
    Task,
    TimeSheet,
    TimeSheetCache,
} from '../../common/types';
import {Reducer} from 'redux';
import {cookieMaster} from '../../utils/CookieMaster';
import {columnsOfTables} from '../../common/shared_data';

let default_init_cols: string[] = [];

let localSt: any = localStorage.getItem('tasksTableCols');
if (localSt) {
    let parsed = JSON.parse(localSt);
    Object.keys(parsed).forEach((item) => {
        default_init_cols.push(parsed[item]);
    });
} else {
    columnsOfTables.forEach((item) => {
        if (item.columnId !== 'executor_id') {
            default_init_cols.push(item.columnId);
        }
    });
}

const initialState = {
    currentTariff: 'EASY FREE',
    step: 1,
    members: 1,
    months: 1,
    paid_success: false,
    paymentDetails: {
        payer_name: '',
        payer_surname: '',
        payer_email: '',
        country: 'Россия',
        city: '',
        payer_address: '',
        postal_code: '',
        pricing_plan_id: 1
    }
};

export type PaymentState = typeof initialState;

const reducer: Reducer<PaymentState, PaymentsAction> = (
    state = initialState,
    action
) => {
    switch (action.type) {
        case types.CHANGE_STEP:
            return {
                ...state,
                step: action.stepNumber,
            };

        case types.CHANGE_PRICE_PARTS:
            let members = action.members
            let months = action.months

            let new_obj = {
                ...state
            }
            if (members) new_obj['members'] = members
            if (months) new_obj['months'] = months

            return new_obj

        case types.PAYMENT_DETAILS:
            // console.log(action.value)
            let yy = {...state.paymentDetails}
            yy[Object.keys(action.value)[0]] = Object.values(action.value)[0]

            // console.log(yy)

            return {
                ...state,
                paymentDetails: yy
            };

        case types.PAID_SUCCESS:
            return {
                ...state,
                paid_success: action.value,
            };

        default:
            return state;
    }
};

export default reducer;
